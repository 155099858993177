<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm" @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="title">
                <el-input size="small" v-model="searchForm.title" placeholder="请输入数据源名称" clearable></el-input>
            </el-form-item>
            <el-form-item prop="searchDates">
                <el-date-picker
                        v-model="searchForm.searchDates"
                        type="daterange"
                        size="small"
                        align="right"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="更新起始时间"
                        end-placeholder="更新结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        prop="id"
                        show-overflow-tooltip sortable
                        label="序号">
                </el-table-column>
                <el-table-column
                        prop="sourceName"
                        show-overflow-tooltip sortable
                        label="数据源名称">
                </el-table-column>
                <el-table-column
                        prop="sourceDesc"
                        show-overflow-tooltip sortable
                        label="数据源描述">
                </el-table-column>
                <el-table-column
                        prop="sourceType"
                        show-overflow-tooltip sortable
                        label="数据源类型">
                </el-table-column>
                <el-table-column
                        prop="connectState"
                        show-overflow-tooltip sortable
                        label="测试情况">
                        <template slot-scope="scope">{{scope.row.connectState == 0 ? '正常' : '断开'}}</template>
                </el-table-column>
                <el-table-column
                        prop="updateTime"
                        show-overflow-tooltip sortable
                        label="更新时间">
                </el-table-column>
                <el-table-column
                        prop="updateUserName"
                        show-overflow-tooltip
                        label="更新人">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain v-show="hasPermission('admin:source:test')"
                                   @click="open(scope.row.connectState)">测试</el-button>
                        <el-button size="mini" type="primary" plain v-show="hasPermission('admin:source:edit')"
                                   @click="view('edit', scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" plain v-show="hasPermission('admin:source:del')"
                                   @click="del(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--新建、编辑-->
        <AddMetadataForm ref="addRecordForm" @refreshDataList="refreshList"></AddMetadataForm>
    </div>
</template>

<script>
    import AddMetadataForm from './collectionSourceForm' // 新建弹窗
    export default {
        components: {AddMetadataForm},
        data() {
            return {
                searchForm: {
                    title: "",
                    searchDates: [],
                    startTime: '',
                    endTime: ''
                },
                sceneOpt: [
                    {
                        label: '全部',
                    },
                    {
                        label: '我上传的文件',
                    }
                ],
                dataList: [
                    {}
                ],
                pageNo: 0,
                pageSize: 10,
                total: 0,
                loading: false,
                dataListSelections: [],
                drawerShow: false,
                moduleId: 34,   // 当前请求拖拽排序数据id
                setShow: false, // 是否显示列表设置拖拽排序弹窗
            }
        },
        activated() {
            this.refreshList()
        },
        watch: {
            searchDates() {
                if (this.searchDates) {
                    this.searchForm.beginDate = this.searchDates[0]
                    this.searchForm.endDate = this.searchDates[1]
                } else {
                    this.searchForm.beginDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
        methods: {
            // 新建、编辑
            view(method, row) {
                this.$refs.addRecordForm.init(method, row)
            },
            // 获取数据列表
            refreshList() {
                // this.loading = true
                this.$axios(this.api.rcPage.datasourceConfigList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'type': this.searchForm.type,
                    'sourceName': this.searchForm.title,
                    'startTime': this.searchForm.searchDates[0],
                    'endTime': this.searchForm.searchDates[1]
                }, 'get').then(data => {
                    this.dataList = data.data.records
                    this.total = parseInt(data.data.total);
                    this.loading = false

                })
            },
            // 查询目录
            getFileList() {
                this.loading = true
                this.$axios(this.api.common.culturalRelicsList, {}, 'get').then(data => {
                    if (data && data.status) {
                    }
                })
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(ids)
                    this.$axios(this.api.rcPage.datasourceConfigRemoveByIds, [ids], 'post').then(data => {
                        if (data && data.status) {
                            this.$message({
                                message: data.msg,
                                type: 'success',
                                duration: 1500
                            })
                            this.refreshList()
                        }
                    })
                })
            },
            open(connectState) {
                this.$message.info("正在向该数据源发送请求...")
                let timer = setTimeout(() => {
                    //需要定时执行的代码
                    this.$alert("响应成功，该数据源正常运行中")
                }, 1500)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()

            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            resetSearch() {
                this.searchDates = []
                this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.refreshList()
                })
            }
        }
    }
</script>
