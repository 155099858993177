<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="140px" @submit.native.prevent>
            <el-form-item label="数据源名称：" prop="sourceName">
                <el-input v-model="inputForm.sourceName" placeholder="请输入数据源名称"></el-input>
            </el-form-item>
            <el-form-item label="数据源描述：" prop="sourceDesc">
                <el-input v-model="inputForm.sourceDesc" placeholder="请输入数据源描述"></el-input>
            </el-form-item>
            <el-form-item label="数据库类型：" prop="sourceType">
                <el-select v-model="inputForm.sourceType" placeholder="请选择数据库类型" style="width: 100%">
                    <el-option
                            v-for="item in sourceTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id: '',
                    sourceName: '',
                    sourceDesc: '',
                    sourceType: ''
                },
                fieldNameOptions: [],
                sourceTypes: [
                            {value: 'Mysql', label: 'mysql'},
                            {value: 'SqlServer', label: 'SqlServer'},
                            {value: 'Oracle', label: 'Oracle'}
                    ],
                dataRule: {
                    fieldName: [
                        {required: true, message: '业务字段名不能为空', trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                if (method === 'add') {
                    this.title = `新增采集源`
                } else if (method === 'edit') {
                    this.title = '编辑采集源'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.title==`新增采集源`){
                            
                        }else{
                            this.$axios(this.api.rcPage.datasourceConfigUpdateById, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message({
                                        message: '操作成功',
                                        type: 'success',
                                        duration: 1500
                                    })
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
